import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';

export const CrmNavItems: NavItem = {
  text: 'CRM',
  allowedRoles: [ApplicationRoles.crm],
  layout: <Layout />,
  subItems: [
    {
      text: 'Manage Hotels',
      path: applicationRoutes.crm.hotel.list,
      async lazy() {
        let component = await import('src/containers/crm/hotel/HotelManagement');
        return { Component: component.default };
      }
    },
    {
      text: 'Manage Companies',
      path: applicationRoutes.crm.company.list,
      async lazy() {
        let component = await import('src/containers/crm/company/CompanyManagement');
        return { Component: component.default };
      }
    },
    {
      text: 'Manage Leads',
      path: applicationRoutes.crm.leads.list,
      async lazy() {
        let component = await import('src-new/features/leads/LeadManagement');
        return { Component: component.LeadManagement };
      }
    },

    {
      path: applicationRoutes.crm.hotel.add,
      async lazy() {
        let component = await import('src/containers/crm/hotel/AddHotelForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.hotel.edit + '/:id',
      async lazy() {
        let component = await import('src/containers/crm/hotel/EditHotelForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.company.add,
      async lazy() {
        let component = await import('src/containers/crm/company/AddCompanyForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.company.edit + '/:id',
      async lazy() {
        let component = await import('src/containers/crm/company/EditCompany/EditCompanyForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.companyProject.email,
      async lazy() {
        let component = await import('src/containers/crm/companyProjects/GroupEmail/GroupEmailLayoutContainer');
        return { Component: component.default };
      }
    },

    {
      path: applicationRoutes.crm.companyProject.edit + '/:id',
      async lazy() {
        let component = await import('src/containers/crm/companyProjects/EditGroupForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.companyProject.dashboard,
      async lazy() {
        let component = await import('src/containers/crm/companyProjects/GroupDashboard');
        return { Component: component.default };
      }
    }
  ]
};
