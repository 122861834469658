export const masks = {
  creditCardNumber: '9999-****-****-9999',
  monthYear: '99/99',
  ccv: '999',
  transitNumber: '99999',
  institutionNumber: '999',
  accountNumber: '999999999999',
  postalCode: 'a9a 9a9',
  postalCodeFsa: 'a9a',
  lockoutPostalCode: 'a9a9a9',
  zipCode: '99999',
  phone: '(999) 999-9999',
  currency: '$999999999'
};
