import dayjs from 'dayjs';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { MirProcessingStatus } from 'src-new/features/mirAdmin/types/mirProcessingStatus';
import formatters from '../../common/formatters';
import ActivityStatusLabel from '../../constants/ActivityStatusLabels';
import ActivityAlertLevel from '../../enums/activities/ActivityAlertLevel';
import ActivityStatus from '../../enums/activities/ActivityStatus';
import ActivityType from '../../enums/activities/ActivityType';
import { getActivityCancelTitle, getActivityTitle } from '../../utils/activities/activityModelHelpers';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { SetDatePropertyName, SetEndDate, SetStartDate } from '../../utils/helpers/dateHelpers';
import { numberIsNullOrZero, stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import DropdownItem from '../forms/DropdownItem';
import EntityFormModel from '../forms/EntityFormModel';
import HotelBookingQuoteInquiryModel from '../QuoteInquiry/HotelBookingQuoteInquiryModel';
import ActivityNoteModel from './ActivityNoteModel';
import MyActivityModel from './MyActivityModel';

class ActivityModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public ActivityType: ActivityType;
  @observable
  // @ts-expect-error added by automation
  public AssignedToUserId: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
  @observable
  // @ts-expect-error added by automation
  public CompanyName: string;
  @observable
  public NewReply: boolean;
  @observable
  // @ts-expect-error added by automation
  public CompanyProjectId: number;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  // @ts-expect-error added by automation
  public DueDate: string;
  @observable
  // @ts-expect-error added by automation
  public DueDateComment: string;
  @observable
  // @ts-expect-error added by automation
  public IsCancelled: boolean;

  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public AssignedUserName: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public Status: ActivityStatus;
  @observable
  // @ts-expect-error added by automation
  public AlertLevel: ActivityAlertLevel;
  @observable
  public Notes: IObservableArray<ActivityNoteModel>;
  @observable
  // @ts-expect-error added by automation
  public StartDate: string;
  @observable
  // @ts-expect-error added by automation
  public EndDate: string;
  @observable
  public HotelBookingQuoteInquiry: HotelBookingQuoteInquiryModel;
  @observable
  // @ts-expect-error added by automation
  public CompanyItineraryEmail: string;
  @observable
  // @ts-expect-error added by automation
  public MirProcessingStatus: MirProcessingStatus;
  @observable
  // @ts-expect-error added by automation
  public MirProcessingStatusString: string;
  @observable
  // @ts-expect-error added by automation
  public MirFileName: string;

  public constructor() {
    super();
    makeObservable(this);
    this.Notes = observable.array(new Array<ActivityNoteModel>());
    this.HotelBookingQuoteInquiry = new HotelBookingQuoteInquiryModel();
    this.NewReply = false;
  }

  @computed
  public get GetHotelName() {
    if (this.HotelBookingQuoteInquiry) {
      return this.HotelBookingQuoteInquiry.GetHotelName;
    }
    return '';
  }

  @computed
  public get GetHotelId() {
    if (this.HotelBookingQuoteInquiry) {
      return this.HotelBookingQuoteInquiry.GetHotelId;
    }
    return null;
  }

  @computed
  public get HasItinerary() {
    return this.HotelBookingQuoteInquiry.ItinerarySelectedRateId !== null;
  }

  @computed
  public get HasReportingRequirements() {
    return (
      this.HotelBookingQuoteInquiry.ReportingRequirements !== undefined &&
      this.HotelBookingQuoteInquiry.ReportingRequirements.length > 0
    );
  }

  @computed
  public get EmailNotes() {
    return this.Notes.filter(
      (x) => (!stringIsNullOrEmpty(x.FileHandle) && x.FileName.toLowerCase().endsWith('eml')) || !x.FileName
    ).reverse();
  }

  @computed
  public get DueDateMoment() {
    return this.DueDate ? dayjs.utc(this.DueDate).local() : null;
  }

  @computed
  public get CreatedOnMoment() {
    return this.CreatedOn ? dayjs.utc(this.CreatedOn).local() : null;
  }

  @computed
  public get CreatedInformation(): string {
    return `${this.CreatedBy} - ${this.CreatedOnFormatted}`;
  }

  @computed
  public get CreatedOnFormatted(): string {
    return `${dayjs.utc(this.CreatedOn).local().format(formatters.momentDateTimeUSA)}`;
  }

  @computed
  public get CanSelectRates() {
    let isNull = false;
    stringIsNullOrEmpty(this.HotelBookingQuoteInquiry.StartDate) && (isNull = true);
    stringIsNullOrEmpty(this.HotelBookingQuoteInquiry.EndDate) && (isNull = true);
    return isNull;
  }

  @computed
  public get CanQuote() {
    let isNull = { check: false, message: new Array<string>() };
    // eslint-disable-next-line array-callback-return
    this.HotelBookingQuoteInquiry.HotelRates.map((value) => {
      if (stringIsNullOrEmpty(value.CancellationPolicy)) {
        isNull.message.push(!!value.HotelName ? `${value.HotelName} - Cancellation Policy` : 'Cancellation Policy');
      }
      if (numberIsNullOrZero(value.Rate)) {
        isNull.message.push(!!value.HotelName ? `${value.HotelName} - Rate` : 'Rate');
      }
    });
    if (stringIsNullOrEmpty(this.HotelBookingQuoteInquiry.StartDate)) {
      isNull.message.push('In Date');
    }
    if (stringIsNullOrEmpty(this.HotelBookingQuoteInquiry.EndDate)) {
      isNull.message.push('Out Date');
    }
    if (this.HotelBookingQuoteInquiry.ItinerarySent) {
      isNull.message.push('Itinerary sent');
    }

    isNull.check = isNull.message.length > 0;
    return isNull;
  }

  @computed
  public get CanEnableSelectRateForItineraryButtons() {
    let isNull = { check: false, message: new Array<string>() };
    this.HotelBookingQuoteInquiry.CreditCardTypes.forEach((element) => {
      if (element.CreditCardTypeId === null || element.CreditCardTypeId === undefined) {
        isNull.message.push('Form of Payment');
      }
    });

    isNull.check = isNull.message.length > 0;
    return isNull;
  }

  @computed
  public get ActivityTitle() {
    return getActivityTitle(this);
  }

  @computed
  public get ActivityCancelledTitle() {
    return getActivityCancelTitle(this);
  }

  @computed
  public get InvoiceValidationResults() {
    let hasError = false;
    let messages: string[] = [];

    if (!this.HotelBookingQuoteInquiry) {
      hasError = true;
      messages.push('No Hotel Booking Quote Inquiry');
      return { bool: hasError, message: messages };
    }
    for (let element of this.HotelBookingQuoteInquiry.CreditCardTypes) {
      if (
        element.CreditCardTypeId === null ||
        element.CreditCardTypeId === undefined ||
        element.CreditCardTypeId === -1 ||
        stringIsNullOrEmpty(element.CardValue) ||
        element.CardValue.length < 4
      ) {
        hasError = true;
        messages.push('Credit Card must be set and 4 digits entered for number');
        break;
      }
    }

    if (
      this.MirProcessingStatus !== MirProcessingStatus.Validated &&
      this.MirProcessingStatus !== MirProcessingStatus.Exported
    ) {
      hasError = true;
      messages.push('MIR Status is not validated');
    }

    if (stringIsNullOrEmpty(this.HotelBookingQuoteInquiry.InvoiceDate)) {
      hasError = true;
      messages.push('Invoice date is required');
    }
    if (
      this.HotelBookingQuoteInquiry.FolioTotal !=
      this.HotelBookingQuoteInquiry.TotalFareAmount(
        this.HotelBookingQuoteInquiry.TotalAveragedDays(),
        this.HotelBookingQuoteInquiry.ItinerarySelectedRate.Rate
      )
    ) {
      hasError = true;
      messages.push(
        `Total fare (${this.HotelBookingQuoteInquiry.TotalFareAmount(
          this.HotelBookingQuoteInquiry.TotalAveragedDays(),
          this.HotelBookingQuoteInquiry.ItinerarySelectedRate.Rate
        )}) is not equal to the Folio Total (${this.HotelBookingQuoteInquiry.FolioTotal})`
      );
    }

    if (this.HotelBookingQuoteInquiry.TotalAveragedDays() != this.HotelBookingQuoteInquiry.TotalNights) {
      hasError = true;
      messages.push(
        `Total nights (${this.HotelBookingQuoteInquiry.TotalNights}) are not equal to the averaged number of nights (${this.HotelBookingQuoteInquiry.TotalAveragedDays()})`
      );
    }
    if (this.HotelBookingQuoteInquiry.AttachFolio && this.HotelBookingQuoteInquiry.SelectedPdfs.length > 0) {
      hasError = true;
      messages.push('Client has requested no attachments to be sent');
    }
    return { hasError, messages };
  }

  @computed
  public get GetAllStatusOptions() {
    const labels = [
      ActivityStatus.None,
      ActivityStatus.Waiting,
      ActivityStatus.Cancelled,
      ActivityStatus.Holding,
      ActivityStatus.Delete,
      ActivityStatus.Archive,
      ActivityStatus.BookingComplete,
      ActivityStatus.CheckedOut,
      ActivityStatus.PreReconciliation,
      ActivityStatus.IssueReconciliation,
      ActivityStatus.Reconciled
    ].map((option) => {
      return { label: ActivityStatusLabel.get(option), value: option };
    });
    // @ts-expect-error added by automation
    return labels.map((element) => new DropdownItem(element.label, element.value));
  }

  @computed
  public get GetStatusOptions() {
    const StatusOptions = [
      ActivityStatus.None,
      ActivityStatus.Waiting,
      ActivityStatus.Cancelled,
      ActivityStatus.Holding,
      ActivityStatus.Delete,
      ActivityStatus.Archive,
      ActivityStatus.BookingComplete
    ].map((option) => {
      return { label: ActivityStatusLabel.get(option), value: option };
    });
    const ReconciliationStatusOptions = [
      ActivityStatus.BookingComplete,
      ActivityStatus.CheckedOut,
      ActivityStatus.PreReconciliation,
      ActivityStatus.IssueReconciliation,
      ActivityStatus.Reconciled
    ].map((option) => {
      return { label: ActivityStatusLabel.get(option), value: option };
    });
    return this.HotelBookingQuoteInquiry.ItinerarySelectedRateId !== null
      ? StatusOptions.concat(ReconciliationStatusOptions).map(
          // @ts-expect-error added by automation
          (element) => new DropdownItem(element.label, element.value)
        )
      : // @ts-expect-error added by automation
        StatusOptions.map((element) => new DropdownItem(element.label, element.value));
  }

  public SetData(dbData: ActivityModel): void {
    super.SetData(dbData, ['Notes', 'HotelBookingQuoteInquiry']);
    arraySetData(this.Notes, ActivityNoteModel, dbData.Notes);
    SetStartDate(dbData, this);
    SetEndDate(dbData, this);
    SetDatePropertyName(dbData, this, 'DueDate');
    this.HotelBookingQuoteInquiry.SetData(dbData.HotelBookingQuoteInquiry);
  }

  public SetDataFromMyActivityModel = (dbData: MyActivityModel) => {
    super.SetData(dbData, ['Notes']);
    arraySetData(this.Notes, ActivityNoteModel, dbData.Notes);
    SetStartDate(dbData, this);
    SetEndDate(dbData, this);
    SetDatePropertyName(dbData, this, 'DueDate');
  };

  @action
  public SetCompanyName = (value: string) => {
    this.CompanyName = value;
  };
}

export default ActivityModel;
