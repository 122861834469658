const applicationRoutes = {
  authorizedRoot: '/',
  account: {
    myProfile: '/my-profile',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password'
  },
  admin: {
    root: '/admin',
    users: '/admin/users',
    obtUsers: '/admin/obt-users',
    activityManagement: '/admin/activities',
    crmManagement: '/admin/crm',
    addUser: '/admin/users/add',
    editUser: '/admin/users/edit',
    announcement: '/admin/announcements',
    quickDocuments: '/admin/quickDocuments',
    passThrough: '/admin/passthrough',
    emailSignatures: '/admin/emailSignatures',
    itinerarySignatures: '/admin/itinerarySignatures',
    folioEmailList: '/admin/folioEmailList'
  },
  reporting: {
    bookingReport: '/reporting/BookingReport',
    agentActivityReport: '/reporting/AgentActivityReport'
  },

  agent: {
    query: '/agent/query',
    activityInquiry: '/agent/activityinquiry',
    obtInquiry: '/agent/obt-inquiry',
    inbox: '/agent/emails',
    inboxCoordinator: '/agent/emailmanager',
    emailEdit: '/agent/emails/edit',
    folioEdit: '/agent/emails/folio',
    ReconciledEdit: '/agent/emails/reconciliation',
    activityReport: '/agent/activityreport',
    activityFolio: '/agent/activityFolio',
    invoiceGroup: '/agent/invoiceGroup'
  },
  mir: {
    mirFileReport: '/mir/file-report',
    mirActivityReport: '/mir/activity-report',
    mirAgentError: '/mir/agent-error'
  },
  crm: {
    hotel: {
      list: '/crm/hotels',
      add: '/crm/hotels/add',
      edit: '/crm/hotels/edit'
    },
    company: {
      list: '/crm/companies',
      add: '/crm/companies/add',
      edit: '/crm/companies/edit'
    },
    leads: {
      list: '/crm/leads'
    },
    companyProject: {
      list: '/crm/companyprojects',
      email: '/crm/companyprojectemails',
      edit: '/crm/companyprojects/edit',
      dashboard: '/crm/groupdashboard'
    }
  }
};
export default applicationRoutes;
