import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import FormOfPaymentModel from '../common/FormOfPaymentModel';
import { IActiveStateModel } from '../common/Interfaces/IActiveStateModel';
import { INotesModel } from '../common/Interfaces/INotesModel';
import { IRequiredAmenities } from '../common/Interfaces/IRequiredAmenities';
import { BranchOfficeModel } from '../companies/BranchOfficeModel';
import ReportingRequirement from '../companies/ReportingRequirement';
import EntityFormModel from '../forms/EntityFormModel';
import { Amenity } from '../hotel/Amenity';
import { ContactCustomFieldModel } from './ContactCustomFieldModel';
import { CustomerContactInformation } from './CustomerContactInformaton';
import { LoyaltyAccount } from './LoyaltyAccount';

export class Contact extends EntityFormModel implements INotesModel, IActiveStateModel, IRequiredAmenities {
  public constructor() {
    super();
    makeObservable(this);
    this.Active = true;
    this.LoyaltyAccounts = observable.array(new Array<LoyaltyAccount>());
    this.AuthorizedBookerBooksFor = observable.array(new Array<number>());
    this.ReportingRequirements = observable.array(new Array<ReportingRequirement>());
    this.Amenities = observable.array(new Array<Amenity>());
    this.PreferredPaymentMethods = observable.array(new Array<FormOfPaymentModel>());
    this.ContactCustomFields = observable.array(new Array<ContactCustomFieldModel>());
    this.BranchOffice = new BranchOfficeModel();
    this.ObtSuperUser = false;
    this.SelfService = false;
    this.ObtAuthBooker = false;
    this.IncludeInQuote = false;
    this.IncludePriceInItinerary = false;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, [
      'LoyaltyAccounts',
      'PreferredPaymentMethods',
      'Amenities',
      'ContactCustomFields',
      'ReportingRequirements'
    ]);
    arraySetData(this.LoyaltyAccounts, LoyaltyAccount, dbData.LoyaltyAccounts);
    arraySetData(this.ReportingRequirements, ReportingRequirement, dbData.ReportingRequirements);
    arraySetData(this.PreferredPaymentMethods, FormOfPaymentModel, dbData.PreferredPaymentMethods);
    arraySetData(this.ContactCustomFields, ContactCustomFieldModel, dbData.ContactCustomFields);
    arraySetData(this.Amenities, Amenity, dbData.Amenities);
  }

  @computed
  public get FullName() {
    if (!!this.NickName) return `${this.FirstName} (${this.NickName}) ${this.LastName}`;
    return `${this.FirstName} ${this.LastName}`;
  }
  @observable
  // @ts-expect-error added by automation
  public NewlyCreated: boolean;
  @observable
  // @ts-expect-error added by automation
  public LastFourDigitsOfCreditCardOfFile: string;
  @observable
  public Active: boolean;
  @observable
  // @ts-expect-error added by automation
  public FirstName: string;
  @observable
  // @ts-expect-error added by automation
  public LastName: string;
  @observable
  // @ts-expect-error added by automation
  public NickName: string;
  @observable
  // @ts-expect-error added by automation
  public MobilePhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public OfficePhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public BusinessPhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public ToBeVerified: boolean;
  @observable
  // @ts-expect-error added by automation
  public Email: string;
  @observable
  // @ts-expect-error added by automation
  public ObtLoginEmail: string;
  @observable
  // @ts-expect-error added by automation
  public Notes: string;
  @observable
  // @ts-expect-error added by automation
  public CodeRedNote: string;
  @observable
  public LoyaltyAccounts: IObservableArray<LoyaltyAccount>;
  @observable
  public ReportingRequirements: IObservableArray<ReportingRequirement>;
  @observable
  public ContactCustomFields: IObservableArray<ContactCustomFieldModel>;
  @observable
  public AuthorizedBookerBooksFor: IObservableArray<number>;
  @observable
  // @ts-expect-error added by automation
  public ReceiverOfItinerary: boolean;
  @observable
  public SelfService: boolean;
  @observable
  // @ts-expect-error added by automation
  public CustomerContactInformation: CustomerContactInformation;
  @observable
  // @ts-expect-error added by automation
  public CustomerContactInformationId: number;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
  @observable
  // @ts-expect-error added by automation
  public IsVip: boolean;
  @observable
  // @ts-expect-error added by automation
  public AuthorizedBooker: boolean;
  @observable
  // @ts-expect-error added by automation
  public AuthorizedBookerApproversName: string;
  @observable
  // @ts-expect-error added by automation
  public PersonalPreferences: string;
  @observable
  // @ts-expect-error added by automation
  public ContactTitle: string;
  @observable
  // @ts-expect-error added by automation
  public AccountingContact: boolean;
  @observable
  // @ts-expect-error added by automation
  public BusinessContact: boolean;
  @observable
  public PreferredPaymentMethods: IObservableArray<FormOfPaymentModel>;
  @observable
  public BranchOffice: BranchOfficeModel;
  @observable
  public Amenities: IObservableArray<Amenity>;
  @observable
  public ObtSuperUser: boolean;
  @observable
  public IncludeInQuote: boolean;
  @observable
  public IncludePriceInItinerary: boolean;

  @observable
  public ObtAuthBooker: boolean;

  @observable
  // @ts-expect-error added by automation
  public GdsSyncId: string;
  @action
  public ToggleVerified = () => {
    this.ToBeVerified = !this.ToBeVerified;
  };

  @computed
  public get ContactTypeString(): string {
    if (this.AccountingContact) {
      return 'Accounting Contact';
    }
    if (this.BusinessContact) {
      return 'Business Contact';
    }
    return 'Traveler';
  }
}
