import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';

import Home from 'src/containers/layout/Home';
import Layout from 'src/containers/layout/Layout';
import { BuildRoute } from './RouteBuilder';
import { RouteError } from './RouteError';
import { AccountManagementItems, LoginRouteItem, MyAccountItem } from './SectionRoutes/AccountRoutes';
import { AdminNavItems } from './SectionRoutes/AdminRoutes';
import { AgentEmailNavItems } from './SectionRoutes/AgentEmailRoutes';
import { AgentQueryNavItem } from './SectionRoutes/AgentQueryRoutes';
import { CrmNavItems } from './SectionRoutes/CrmRoutes';
import { FolioNavItems } from './SectionRoutes/FolioRoutes';
import { GroupNavItems } from './SectionRoutes/GroupRoutes';
import { MirNavItems } from './SectionRoutes/MirRoutes';
import { ReportingNavItems } from './SectionRoutes/ReportingRoutes';

export const AuthenticatedRouteItems = [
  AgentQueryNavItem,
  AgentEmailNavItems,
  ReportingNavItems,
  FolioNavItems,
  CrmNavItems,
  GroupNavItems,
  MirNavItems,
  AdminNavItems,
  MyAccountItem,
  AccountManagementItems
];
const UnauthenticatedRouteItems = [LoginRouteItem, AccountManagementItems];

function getRoutes() {
  const navRoutes = new Array<RouteObject>();
  navRoutes.push({
    path: '/',
    element: <Layout />,
    errorElement: <RouteError />,
    children: [{ path: '/', element: <Home />, errorElement: <RouteError /> }]
  });
  AuthenticatedRouteItems.forEach((value) => {
    navRoutes.push(BuildRoute(value));
  });
  UnauthenticatedRouteItems.forEach((value) => {
    navRoutes.push(BuildRoute(value));
  });
  navRoutes.push({
    path: '/spa/*',
    element: <Navigate to={window.location.pathname.toString().replace('/spa', '')} replace />
  });
  return navRoutes;
}

export const FrontierRoutes = createBrowserRouter(getRoutes());
