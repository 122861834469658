import { useAuth } from '@cksoftware/react-base';
import { HubConnection } from '@microsoft/signalr';
import { captureException } from '@sentry/react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { InvokeRpc } from 'src-new/util/hubs/types';
import useHookHub from 'src-new/util/hubs/useHookHub';
import FullPageLoadingSpinner from 'src/components/common/FullPageLoadingSpinner';
import ActivityStatus from 'src/enums/activities/ActivityStatus';
import ActivityPagingModel from 'src/models/activities/ActivityPagingModel';
import IPagedActivityResponse from 'src/models/activities/IPagedActivityResponse';
import IUpdatePagedActivity from 'src/models/activities/IUpdatePagedActivity';
import MyActivityModel from 'src/models/activities/MyActivityModel';
import MyActivityAutoAssignService from 'src/services/AutoAssign/MyActivityAutoAssignService';
import hubEndpoints from 'src/services/Common/hubEndpoints';
import { useUtilityStore } from 'src/stores/useMobxStores';

interface EmailActivitesServerRpc {
  AddToGroup: (groupName: string) => void;
  LoadPage: (page: number, perPage: number) => void;
  RemoveFromGroup: (groupName: string) => void;
}

const URL = hubEndpoints.activities.EmailActivitiesHub.endpointUrl;
export const EmailActivitesHubContext = createContext<{ hubStore: PagedActivitiesHubBase; conn: HubConnection } | null>(
  null
);

export default function EmailActivitesHubProvider({ children }: PropsWithChildren<any>) {
  const [hubStore] = useState(new PagedActivitiesHubBase());
  const [loading, setLoading] = useState(true);
  const [invoke, conn] = useHookHub<EmailActivitesServerRpc>(URL, {
    LoadPage: (a) => {
      runInAction(() => {
        hubStore.loadPage(a);
      });
    },
    UpdatePagedActivity: (a: IUpdatePagedActivity) => {
      runInAction(() => {
        hubStore.addOrUpdatePagedActivity(a);
      });
    },
    ActivityStatusNotOpen: (a) => {
      runInAction(() => {
        hubStore.activityStatusNotOpen(a);
      });
    }
  });

  const user = useAuth();
  const utilStore = useUtilityStore();
  useEffect(() => {
    if (invoke) {
      setLoading(false);
      runInAction(() => {
        if (user.user) {
          hubStore.currentUserId = user.user.UserId;
          hubStore.invoker = invoke;
          hubStore.autoAssignFeatureFlag = utilStore.featureFlags.AutoAssignFeatureFlag;
        }
      });
    }
  }, [invoke, user.user?.UserId, utilStore.featureFlags.AutoAssignFeatureFlag]);

  if (loading || !conn || !hubStore) {
    return <FullPageLoadingSpinner />;
  }

  return <EmailActivitesHubContext.Provider value={{ hubStore, conn }}>{children}</EmailActivitesHubContext.Provider>;
}

export class PagedActivitiesHubBase {
  public ActivityPagingModel = new ActivityPagingModel(200);

  public hasLoaded: boolean = false;
  public PauseLoading: boolean = false;
  public InsertPause: boolean = false;
  public isLoading: boolean = false;
  public autoAssignFeatureFlag: boolean = false;
  public currentUserId?: string;

  public invoker: InvokeRpc<EmailActivitesServerRpc> | undefined;

  public constructor() {
    makeObservable(this, {
      hasLoaded: observable,
      PauseLoading: observable,
      InsertPause: observable,
      isLoading: observable,
      loadPage: action,
      addOrUpdatePagedActivity: action,
      activityStatusNotOpen: action
    });
  }

  public TogglePauseLoading = async () => {
    this.PauseLoading = !this.PauseLoading;
    if (!this.PauseLoading) {
      this.pauseLoadingTrigger();
    }
  };

  public ToggleInsertPause = async () => {
    this.InsertPause = !this.InsertPause;
    if (!this.InsertPause) {
      this.pauseLoadingTrigger();
    }
  };

  private pauseLoadingTrigger() {
    this.invoker?.('LoadPage', this.ActivityPagingModel.CurrentPage, this.ActivityPagingModel.PerPage);
  }

  public AddToOverviewGroup() {
    this.hasLoaded = false;
    this.isLoading = true;
    this.invoker?.('AddToGroup', 'Overview');
    this.pauseLoadingTrigger();
  }

  public LoadActivitiesForPage(page: number) {
    if (page < 1) {
      page = 1;
    }
    this.isLoading = true;
    this.invoker?.('LoadPage', page, this.ActivityPagingModel.PerPage);
  }

  public async loadPage(data: IPagedActivityResponse) {
    if (this.PauseLoading) return;
    this.ActivityPagingModel.Activities.replace(data.Activities);
    const myActivitiesArray = new Array<MyActivityModel>();
    for (const index in data.MyActivities) {
      try {
        const am = new MyActivityModel();
        am.SetData(data.MyActivities[index]);
        myActivitiesArray.push(am);
      } catch (error) {
        captureException(error);
      }
    }
    this.ActivityPagingModel.MyActivities.replace(myActivitiesArray);
    this.ActivityPagingModel.CurrentPage = data.CurrentPage;
    this.ActivityPagingModel.PerPage = data.PerPage;
    this.ActivityPagingModel.UpdatePages(data);
    this.hasLoaded = true;
    this.isLoading = false;
    this.autoAssignMyActivity();
  }

  private async autoAssignMyActivity() {
    if (this.autoAssignFeatureFlag && this.myActivitiesLength) {
      const service = new MyActivityAutoAssignService();
      await service.set();
    }
  }

  private async addOrUpdateActivity(model: IUpdatePagedActivity) {
    if (this.PauseLoading) {
      return;
    }
    const foundIndex = this.ActivityPagingModel.Activities.findIndex((x) => x.ActivityId === model.Activity.Id);
    if (foundIndex !== -1) {
      this.ActivityPagingModel.Activities[foundIndex] = model.OverviewModel;
    } else if (!this.InsertPause) {
      this.ActivityPagingModel.Activities.push(model.OverviewModel);
    }

    //Also update right panel with assigned activity if found or created
    const myFoundIndex = this.ActivityPagingModel.MyActivities.findIndex((x) => x.Id === model.Activity.Id);
    if (model.Activity.AssignedToUserId === this.currentUserId) {
      try {
        const am = new MyActivityModel();
        am.SetData(model.Activity);
        if (myFoundIndex !== -1) {
          this.ActivityPagingModel.MyActivities[myFoundIndex].SetData(model.Activity);
        } else {
          this.ActivityPagingModel.MyActivities.push(am);
        }
      } catch (error) {
        captureException(error);
      }
    } else if (myFoundIndex !== -1) {
      //It's in the array but no longer matches on userId, we should remove
      this.ActivityPagingModel.MyActivities.splice(myFoundIndex, 1);
    }
    await this.autoAssignMyActivity();
  }

  private get myActivitiesLength() {
    return this.ActivityPagingModel.MyActivities.findIndex((x) => x.Status == ActivityStatus.None) == -1;
  }

  public async addOrUpdatePagedActivity(data: IUpdatePagedActivity) {
    if (this.PauseLoading) return;
    this.ActivityPagingModel.UpdatePages(data);
    await this.addOrUpdateActivity(data);
  }

  public activityStatusNotOpen(data: IUpdatePagedActivity) {
    if (this.PauseLoading) return;
    this.ActivityPagingModel.UpdatePages(data);

    const foundIndex = this.ActivityPagingModel.Activities.findIndex((x) => x.ActivityId === data.Activity.Id);
    if (foundIndex !== -1 && !this.InsertPause) {
      this.ActivityPagingModel.Activities.splice(foundIndex, 1);
    }

    const myFoundIndex = this.ActivityPagingModel.MyActivities.findIndex((x) => x.Id === data.Activity.Id);
    if (myFoundIndex !== -1) {
      this.ActivityPagingModel.MyActivities.splice(myFoundIndex, 1);
    }
  }
}
