import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import ActivityOverviewSortProvider from '../../utils/activities/ActivityOverviewSortProvider';
import MyActivitiesSortProvider from '../../utils/activities/MyActivitiesSortProvider';
import { IEmailActivityOverviewModel } from './IEmailActivityOverviewModel';
import IPagedActivityBase from './IPagedActivityBase';
import MyActivityModel from './MyActivityModel';
import InboxManagerPrioritySortProvider from '../../utils/activities/InboxManagerPrioritySortProvider';

interface IPages {
  PageNumber: number;
}

class ActivityPagingModel {
  public InboxManagerSortProvider: InboxManagerPrioritySortProvider;
  public ActivitySortProvider: ActivityOverviewSortProvider;
  public MyActivitySortProvider: MyActivitiesSortProvider;
  @observable
  public Activities: IObservableArray<IEmailActivityOverviewModel>;
  @observable
  public MyActivities: IObservableArray<MyActivityModel>;
  @observable
  public CurrentPage: number;
  @observable
  public PerPage: number;
  @observable
  public TotalActivities: number;
  @observable
  // @ts-expect-error added by automation
  public UrgentActivities: number;
  @observable
  // @ts-expect-error added by automation
  public TodayActivities: number;
  @observable
  // @ts-expect-error added by automation
  public TomorrowActivities: number;
  @observable
  // @ts-expect-error added by automation
  public UpcomingActivities: number;

  public constructor(perPage?: number) {
    makeObservable(this);
    this.Activities = observable.array(new Array<IEmailActivityOverviewModel>());
    this.MyActivities = observable.array(new Array<MyActivityModel>());
    this.CurrentPage = 1;
    // @ts-expect-error added by automation
    this.PerPage = perPage;
    this.TotalActivities = this.Activities.length;
    this.ActivitySortProvider = new ActivityOverviewSortProvider(this);
    this.MyActivitySortProvider = new MyActivitiesSortProvider(this);
    this.InboxManagerSortProvider = new InboxManagerPrioritySortProvider(this);
  }

  @computed
  public get Offset() {
    return this.PerPage * this.CurrentPage;
  }

  @computed
  public get PageCount() {
    return Math.ceil(this.TotalActivities / this.PerPage);
  }

  @computed
  public get Pages() {
    const pages = new Array<IPages>();
    for (let i = 0; i < this.PageCount; i++) {
      pages.push({ PageNumber: i + 1 });
    }
    return pages;
  }

  @computed
  public get HasActivitiesForInvoiceGroup() {
    return { check: this.Activities.length == 0, message: ['Activities'] };
  }

  @computed
  public get HasInvoiceNumberForInvoiceGroup() {
    let check = false;
    let message = new Array<string>();
    if (this.Activities.length == 0) {
      check = true;
      message.push('Activities');
    }
    if (this.Activities.length > 0) {
      this.Activities.forEach((activity) => {
        if (activity.InvoiceNumber == null) {
          check = true;
          message.push(`FLSQ: ${activity.HotelBookingQuoteInquiryId} Invoice Number`);
        }
      });
    }
    return { check: check, message: message };
  }

  @action
  public UpdatePages = (data: IPagedActivityBase) => {
    this.TotalActivities = data.TotalActivities;
    this.UrgentActivities = data.UrgentActivities;
    this.TodayActivities = data.TodayActivities;
    this.UpcomingActivities = data.UpcomingActivities;
    this.TomorrowActivities = data.TomorrowActivities;
  };
}

export default ActivityPagingModel;
