import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import NarrowLayout from 'src/containers/layout/NarrowLayout';

export const AdminNavItems: NavItem = {
  text: 'Admin',
  allowedRoles: [ApplicationRoles.userAdmin, ApplicationRoles.systemAdmin],
  layout: <NarrowLayout />,
  subItems: [
    {
      text: 'Manage Users',
      path: applicationRoutes.admin.users,
      allowedRoles: [ApplicationRoles.userAdmin],
      async lazy() {
        let component = await import('src-new/features/userAdmin/ManageUsers');
        return { Component: component.ManageUsers };
      }
    },
    {
      text: 'Manage OBT Users',
      path: applicationRoutes.admin.obtUsers,
      allowedRoles: [ApplicationRoles.userAdmin],
      async lazy() {
        let component = await import('src-new/features/userAdmin/ManageObtUsers');
        return { Component: component.ManageObtUsers };
      }
    },
    {
      text: 'Crm Management',
      path: applicationRoutes.admin.crmManagement,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        let component = await import('src/containers/admin/applicationManagement/crmAdmin/CrmManagementMain');
        return { Component: component.default };
      }
    },
    {
      text: 'Activity Management',
      path: applicationRoutes.admin.activityManagement,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        let component = await import('src/containers/admin/applicationManagement/activityAdmin/ActivityManagementMain');
        return { Component: component.default };
      }
    },
    {
      text: 'Email/SMS Templates',
      path: applicationRoutes.admin.emailSignatures,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        let component = await import(
          'src/containers/admin/applicationManagement/emailSignatures/EmailSignatureManagement'
        );
        return { Component: component.default };
      }
    },
    {
      text: 'Announcements',
      path: applicationRoutes.admin.announcement,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        let component = await import('src/containers/admin/applicationManagement/announcements/AnnouncementManagement');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.admin.passThrough,
      allowedRoles: [ApplicationRoles.developer],
      async lazy() {
        let component = await import('src/containers/passthrough/ApiPassthrough');
        return { Component: component.ApiPassthrough };
      }
    }
  ]
};
